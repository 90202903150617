<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Category</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="category-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            dense
            background-color="white"
            @change="onChange($event)"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-textarea
            label="Description *"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-select
            label="Color *"
            :items="colors"
            v-model="fields.color"
            item-value="value"
            item-text="label"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('color')"
            :error-messages="errors['color']"
          ></v-select>
          <v-file-input
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            prepend-icon="mdi-camera"
            label="Thumbnail *"
            outlined
            background-color="white"
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
          ></v-file-input>

          <v-text-field
            label="Display Order"
            v-model="fields.display_order"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('display_order')"
            :error-messages="errors['display_order']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="category-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        slug: null,
        description: null,
        thumbnail: null,
        thumbnail_has_changed: false,
        is_parent: false,
        is_quick_link: false,
        parents: [],
        template_type: null,
        color: null,
        display_order: null,
        isEditing: false,
      },
      category: null,
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },

    parentCategories() {
      return this.$store.state.eow.products["categories"].filter((c) => {
        return c.is_parent === true;
      });
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    onChange(event) {
      this.fields.slug = event;
    },
    openForm: function (category = null) {
      if (category !== null) {
        this.isEditing = true;
        this.category = category;
        this.fields.isEditing = true;
        this.fields.name = category.name;
        this.fields.slug = category.slug;
        this.fields.description = category.description;
        this.fields.thumbnail = category.thumbnail_image
          ? new File([category.thumbnail_image], category.thumbnail_image)
          : null;
        this.fields.is_parent = category.is_parent;
        this.fields.is_quick_link = category.is_quick_link;
        this.fields.parents = category.parents.map((c) => c.id);
        this.fields.template_type = category.template_type;
        this.fields.display_order = category.display_order;
        this.fields.color = category.color;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.isEditing = false;
      this.fields.slug = null;
      this.fields.description = null;
      this.fields.thumbnail = null;
      this.fields.thumbnail_has_changed = false;
      this.fields.is_parent = false;
      this.fields.is_quick_link = false;
      this.fields.parents = [];
      this.fields.color = null;
      this.fields.template_type = null;
      this.fields.display_order = null;
      this.category = null;
      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let fields = ((type) => {
        if (type === "straights") {
          return this.$refs.straightDetails.saveForm();
        }

        return null;
      })(this.fields.type);

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          ...fields,
          ...this.fields,
        },
      };

      if (this.isEditing) {
        payload.categoryId = this.category.id;
      }

      this.$store
        .dispatch("eow/productsStore/saveCategory", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    thumbnailHasChanged: function () {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
