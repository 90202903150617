<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h2>Brands</h2>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Brands"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              class="mr-2"
              depressed
              light
              :to="{ name: 'module-eow-products' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Products
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.categoryForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Category</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="brands"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are currently no brands"
      >
        <template v-slot:item.is_parent="{ item }">
          <v-icon small v-if="item.is_parent">mdi-check</v-icon>
        </template>
        <template v-slot:item.is_quick_link="{ item }">
          <v-icon small v-if="item.is_quick_link">mdi-check</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.categoryForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteCategory(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CategoryForm ref="categoryForm" />
    <v-dialog v-model="deleteCategory.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Category</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteCategory.category.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteCategory"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteCategory.loading"
            @click="saveDeleteCategory"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CategoryForm from "./components/CategoryForm";

export default {
  components: {
    CategoryForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-eow-products",
          },
        },
        {
          text: "Brands",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Is A Parent?", value: "is_parent" },
        { text: "Is A Quick Link?", value: "is_quick_link" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteCategory: {
        dialog: false,
        category: {},
        loading: false,
      },
    };
  },

  computed: {
    brands() {
      let brands = this.$store.state.eow.products["brands"];

      if (this.searchTerm !== "") {
        brands = brands.filter((c) => {
          const name = c.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return brands;
    },
  },

  methods: {
    openDeleteCategory(category) {
      this.deleteCategory.category = category;
      this.deleteCategory.dialog = true;
    },

    resetDeleteCategory() {
      this.deleteCategory.dialog = false;
      this.deleteCategory.category = {};
      this.deleteCategory.loading = false;
    },

    saveDeleteCategory() {
      this.deleteCategory.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteCategory", {
          appId: this.$route.params.id,
          categoryId: this.deleteCategory.category.id,
        })
        .then(() => {
          this.resetDeleteCategory();
        })
        .catch(() => {
          this.deleteCategory.loading = false;
        });
    },
  },
};
</script>
